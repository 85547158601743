









































































































import Vue from 'vue';
import { BASE_URL, ENDPOINTS, LOGIN_PAGE_URL, MainApi } from '@/api/mainApi';
import { UserAccount } from '@/interfaces/useraccount/userAccount';
import Avatar from 'vue-avatar-component';
import { Agreement, AgreementType, AgreementTypeIcon, AgreementTypeStr } from '@/interfaces/depot/agreement';
import { SignatureTypeIcon } from '@/interfaces/depot/agreementSignature';
import { Snackbar } from '@/interfaces/presentation/snackbar';
import { FileData } from '@/interfaces/fileData';
import FileUploadComponent from '@/components/FileUploadComponent.vue';
import axios from 'axios';
import { Dialog } from '@/interfaces/presentation/dialog';
import { AgreementSignatureScanResult } from '@/interfaces/depot/agreementSignatureScanResult';
import { isHigherLevelAdmin } from '@/helpers/userAccountHelper';
import { AgreementRepository } from '@/repositories/agreementRepository';
import { BankidSignatureType } from '@/interfaces/bankid/bankidSignatureType';
import {SigningInProgressState} from "@/interfaces/bankid/signingInProgressState";

export default Vue.extend({
  components: {
    FileUploadComponent,
    Avatar,
  },
  data() {
    return {
      agreementTypeStr: AgreementTypeStr,
      agreementTypeIcons: AgreementTypeIcon,
      signatureTypeIcons: SignatureTypeIcon,
      menu: false,
      menuX: 0,
      menuY: 0,
      uploadingSignature: false,
      loading: false,
      agreementRepository: new AgreementRepository(this),
    };
  },
  props: {
    signingSourceId: String,
    agreement: Object as () => Agreement,
  },
  computed: {
    item: {
      get(): Agreement {
        return this.agreement;
      },
      set(val: Agreement) {
        this.$emit('update:agreement', val);
      },
    },
    user(): UserAccount {
      return this.$store.state.userSession.userAccount;
    },
    agreementIsNotDownloadable(): boolean {
      return this.isKeepsakeSubmissionAgreement || this.missingFileDataFromPassword;
    },
    isKeepsakeSubmissionAgreement(): boolean {
      return this.agreement && this.agreement.type === AgreementType.KEEPSAKE_SUBMISSION_AGREEMENT;
    },
    missingFileDataFromPassword(): boolean {
      return this.agreement && this.agreement.agreementSignature && !this.agreement.agreementSignature.fileData && this.agreement.agreementSignature.type === 'PASSWORD';
    },
    showUndoOption(): boolean {
      return !this.item.agreementSignature && this.item.type === AgreementType.KEEPSAKE_EXTRADITION_AGREEMENT;
    },
  },
  filters: {
    agreementTypeName(agreementType, agreementTypeStr) {
      return agreementTypeStr[agreementType];
    },
    agreementTypeIcon(agreementType, agreementTypeIcons) {
      return agreementTypeIcons[agreementType];
    },
    signatureTypeIcon(signatureType, signatureTypeIcons) {
      return signatureTypeIcons[signatureType];
    },
  },
  methods: {
    isHigherLevelAdmin,
    getUserAvatar(user: UserAccount): String {
      if (user.fileData && user.fileData.src) {
        return BASE_URL + user.fileData.src;
      }
      return '';
    },
    agreementClick(event, agreement: Agreement) {
      if (agreement.agreementSignature && agreement.agreementSignature.fileData) {
        // TODO - might need to log this
        window.open(BASE_URL + '/' + MainApi + '/files/' + agreement.agreementSignature.fileData.filename);
      } else {
        event.preventDefault();
        this.menu = false;
        this.menuX = event.clientX;
        this.menuY = event.clientY;
        this.$nextTick(() => {
          this.menu = true;
        });
      }
    },
    undoExtraditionAgreement(): void {
      this.setSigningStatusInStore();
      let dialog: Dialog = {
        active: true,
        title: 'Ångra avtal',
        text: 'Är du säker på att du vill ångra utlämingsavtalet?',
        actionClick: async () => {
          await this.deleteAgreement(this.item.id);
        },
      }; 
      setTimeout(() => {
        this.$store.commit('setCredentialsConfirmDialog', dialog);
      }, 200);
    },
    setSigningStatusInStore(): void {
      // prepare the signing by adding data for it, but it is not yet ready
      let signingInProgressState: SigningInProgressState = {
        bankidSignatureType: BankidSignatureType.PERSONAL_DATA_AGREEMENT,
        //Allows for the redirect back to the correct page after signing
        depotId: this.item.depotId,
        sourceId: this.signingSourceId,
        agreementType: AgreementType.KEEPSAKE_EXTRADITION_AGREEMENT,
        undoExtraditionAgreementId: this.agreement.id,
        ready: false
      };

      this.$store.commit('setSigningInProgress', signingInProgressState);
    },
    async deleteAgreement(agreementId: number): Promise<void> {
      const response = await this.agreementRepository.deleteAgreement(agreementId);
      if (response) {
        //signed agreement to reload page and show keepsakes as not extradited
        const agreement = {
          id: agreementId,
          type: AgreementType.KEEPSAKE_EXTRADITION_AGREEMENT,
        }
        this.signedAgreement(agreement);
      } 
    },
    async signedAgreementFileUploaded(fileData: FileData) {
      if (fileData) {
        this.loading = true;
        let errorMsg: null | string = null;
        let response;
        try {
          response = await axios
            .put(BASE_URL + '/' + MainApi + '/' + ENDPOINTS.AGREEMENTS + '/' + this.item.id + '/' + ENDPOINTS.SIGN, fileData, {
              withCredentials: true,
            })
            .then(response => response.data);
        } catch (error) {
          this.$refs.fileupload.clearFile();
          response = {
            agreementIds: [],
            successfulAgreementScanCount: 0,
            failedScanCount: 0,
            successfulAppendixScanCount: 0,
            successfulAgreementSignedDepotIds: [],
            failedAgreementSignedDepotIds: [],
            totalDocumentPagesCount: 0,
          };
          if (error.response?.status === 401) {
            location.href = LOGIN_PAGE_URL;
          } else if (error.response?.status === 400) {
            errorMsg = 'Det uppladdade dokumentet gick inte att skanna';
          } else if (error.response?.status === 406) {
            errorMsg = 'Mer än ett avtal har identifierats i det uppladdade dokumentet';
          } else if (error.response?.status === 409) {
            errorMsg = 'Avtalet i det uppladdade dokumentet matchar inte valt avtal att signera';
          } else if (error.response?.status === 422) {
            errorMsg = 'Inget avtal har kunnat identifierats i det uppladdade dokumentet';
          }
          console.error(error);
        } finally {
          this.loading = false;
          this.showSignatureResult(response, errorMsg);
          this.refreshAgreements();
          this.signedAgreement(this.item);
        }
      }
    },
    downloadUnsignedAgreement() {
      // keepsake submission agreements are not downloadable
      if (this.isKeepsakeSubmissionAgreement) {
        return;
      }
      try {
        window.open(BASE_URL + '/' + MainApi + '/' + ENDPOINTS.AGREEMENTS + '/' + this.agreement.id + '/' + ENDPOINTS.DOCUMENT + '?officeId=' + this.user.office.id + '&renderAsPdf=true', '_blank');
      } catch (e) {
        console.error(e);
        let snackbar: Snackbar = {
          active: true,
          color: 'error',
          text: 'Något gick fel när avtalet skulle öppnas. Kontakta support om problemet kvarstår',
          timeout: 8000,
          action: null,
        };
        this.$store.commit('setSnackbar', snackbar);
      }
    },
    removeSigning() {
      this.$store.commit('removeSigningInProgress')
    },
    refreshAgreements() {
      this.$emit('refreshAgreements');
      this.uploadingSignature = false;
    },
    signedAgreement(data) {
      this.$emit('signedAgreement', data);
    },
    showSignatureResult(result: AgreementSignatureScanResult, errorMsg?: string) {
      let isSuccess = result.successfulAgreementSignedDepotIds.length > 0;
      let success = isSuccess ? result.successfulAgreementSignedDepotIds.length + ' Avtal signerat!' : null;
      let error = isSuccess ? null : 'Inga avtal kunde signeras.';
      if (error && errorMsg) {
        error += '\n\n Felmeddelande:\n' + errorMsg;
      }
      let text = '';

      text = 'Dokumentet innehöll totalt ' + result.totalDocumentPagesCount + ' sidor. \n';
      text += result.successfulAgreementScanCount + ' sidor identifierades som avtalssidor. \n';
      text += result.successfulAgreementSignedDepotIds.length + ' avtal signerades. \n';
      text += result.successfulAppendixScanCount + ' sidor identifierades som bilagor. \n';
      text += result.failedScanCount + ' sidor kunde inte skannas in. \n';


      let dialog: Dialog = {
        active: true,
        actionBtnText: 'Ok',
        title: 'Skanningsresultat',
        error: error,
        success: success,
        text: text,
        actionClick: () => {
        },
      };
      this.$store.commit('setDialog', dialog);
    },
  },
});
