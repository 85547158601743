























import Vue from "vue";
import {TableOptions} from "@/interfaces/Vuetify";
import {DocumentStatisticsSummary} from "@/interfaces/statistics/documentStatisticsSummary";
import { StatisticsRepository } from "@/repositories/statisticsRepository";

export default Vue.extend({
    props: {
        businessId: Number, // Optional - if we should fetch data for a specific business
        orgId: Number, // Optional - if we should fetch data for organization
    },
    data() {
        return {
            loading: false,
            documentSummary: [] as Array<DocumentStatisticsSummary>,
            errorFetchingData: false,
            statisticsRepoistory: new StatisticsRepository(),
            footerProps: {
                "items-per-page-all-text": "alla",
                "items-per-page-options": [50, 100, 150],
                "items-per-page-text": "Rader per sida"
            },
            tableID: "DocumentStatisticsTable" + (this.orgId ? '_org' : '_buz'),
            tableHeaders: [
                {
                    text: 'Född',
                    value: "birthDecade",
                    align: 'left',
                    sortable: true,
                },
                {
                    text: 'Livsarkiv',
                    value: "lifeArchive",
                    align: 'left',
                    sortable: false,
                },
                {
                    text: 'Digitalt Livsarkiv',
                    value: "digitalLifeArchive",
                    align: 'left',
                    sortable: false,
                },
                {
                    text: 'Framtidsfullmakt',
                    value: "futurePowerOfAttorney",
                    align: 'left',
                    sortable: false,
                },
                {
                    text: 'Övriga Dokument',
                    value: "other",
                    align: 'left',
                    sortable: false,
                },
                {
                    text: 'Testamente',
                    value: "will",
                    align: 'left',
                    sortable: false,
                },
            ],
            optionsProps: {
                sortDesc: [false],
                multiSort: false
            } as TableOptions,
        };
    },
    mounted() {
        if (this.orgId) {
            this.getOrganizationStatistics()
        } else if (this.businessId) {
            this.getBusinessStatistics()
        }
    },
    watch: {
        businessId: function(newId, oldId) {
            if (newId != oldId && newId) {
                this.getBusinessStatistics()
            }
        }
    },
    methods: {
        async getBusinessStatistics()  {
            this.loading = true;
            try {
                const statsResponse = await this.statisticsRepoistory.getDocumentStatisticsForBusiness(this.businessId)
                this.documentSummary = this.fill(statsResponse);
            } catch {
                this.errorFetchingData = true;
            }
            this.loading = false;
        },

        async getOrganizationStatistics() {
            this.loading = true;
            try {
                const statsResponse = await this.statisticsRepoistory.getDocumentStatisticsForOrganization(this.orgId)
                this.documentSummary = this.fill(statsResponse);
            } catch {
                this.errorFetchingData = true;
            }
            this.loading = false;
        },

        /**
         * Adds zero entries between birth decades
         * if no statistics exist - use zero entries between 1940-1980
         * if the range of birth decades is less than four add zero some entries below and above range
         * @param statistics 
         */
        fill(statistics: Array<DocumentStatisticsSummary>): Array<DocumentStatisticsSummary> {
            let minDecade: number
            let maxDecade: number
            
            if (statistics.length === 0) { // use zero values between 1940 - 1980 if no documents exist
                minDecade = 194
                maxDecade = 198
            } else { // find min and max in given data
                minDecade = statistics.map((stat) => Number(stat.birthDecade)).reduce((min, curr) => curr < min ? curr : min, Number(statistics[0].birthDecade))
                maxDecade = statistics.map((stat) => Number(stat.birthDecade)).reduce((max, curr) => curr > max ? curr : max, Number(statistics[0].birthDecade))
            }

            while(maxDecade - minDecade < 4) { // extend data by adding zero entries outside range
                minDecade--
                maxDecade++
            }

            const lookup = new Map(statistics.map(curr => [curr.birthDecade, curr]))
            
            for (let i = minDecade; i <= maxDecade; i++) {
                if (lookup.has(String(i))) {
                    continue
                }
                
                statistics.push({"birthDecade": String(i), "lifeArchive": 0, "digitalLifeArchive": 0, "futurePowerOfAttorney": 0, "other": 0, "will": 0} as DocumentStatisticsSummary)
            }

            return statistics;
        }
    }
});
