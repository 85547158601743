















import Vue from "vue";

export default Vue.extend({
  props: {
    login: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      signingVendor: 'Svensk e-identitet'
    }
  },
  computed: {
    text() {
      if (this.login) {
        return `När du trycker på knappen för att logga in så kommer du att föras till ${this.signingVendor}s hemsida där du genomför en BankID-signering.
          Du får välja om du vill använda QR-kod eller mata in ditt personnummer. Av säkerhetsskäl rekommenderar vi att du alltid väljer QR-kod vid inloggning.
          Innan du genomför själva signeringen, verifiera att just ${this.signingVendor} begär signeringen, och ingen annan.
          Efter genomförd signering loggas du automatiskt in i systemet.`
      } else {
        return `När du trycker på knappen för att logga in så kommer du att föras till ${this.signingVendor}s hemsida där du genomför en BankID-signering.
          Du får välja om du vill använda QR-kod eller mata in ditt personnummer. Av säkerhetsskäl rekommenderar vi att du alltid väljer QR-kod vid inloggning.
          Innan du genomför själva signeringen, verifiera att just ${this.signingVendor} begär signeringen, och ingen annan.
          Efter genomförd signering loggas du automatiskt in i systemet.`
      }
    },
    title() {
      return (this.login ? "Inloggning" : "Signering") + " med BankID"
    }
  },
});
