







































































import Vue from 'vue';
import DepotDataTableComponent from "@/components/depot/DepotDataTableComponent.vue";
import DocumentStatisticsSummary from '@/components/statistics/DocumentStatisticsSummary.vue';
import StatusSelectComponent from "@/components/depot/status/StatusSelectComponent.vue";
import {Status} from "@/interfaces/depot/depot";
import { isAdmin } from '@/helpers/userAccountHelper';

export default Vue.extend({
    components: {
        DepotDataTableComponent,
        DocumentStatisticsSummary,
        StatusSelectComponent,
    },
    data() {
        return {
            depotFilterStatuses: [Status.MARKED_AS_DECEASED_BY_SPAR, Status.MARKED_AS_DECEASED_BY_USER, Status.DECEASED_WORK_IN_PROGRESS]
        }
    },
    computed: {
        showStatistics(): boolean {
            return isAdmin(this.$store.state.userSession.userAccount)
        }
    },
    methods: {}
})
