import { ENDPOINTS, get } from '@/api/mainApi';
import { DocumentStatisticsSummary } from '@/interfaces/statistics/documentStatisticsSummary';
export class StatisticsRepository {
    
    public async getDocumentStatisticsForOrganization(orgId: number): Promise<DocumentStatisticsSummary[]> {
        const url = `${ENDPOINTS.STATISTICS}/${ENDPOINTS.ORGANIZATIONS}/${orgId}/${ENDPOINTS.DOCUMENTS}`

        return get(url);
    }

    public async getDocumentStatisticsForBusiness(businessId: number): Promise<DocumentStatisticsSummary[]> {
        const url = `${ENDPOINTS.STATISTICS}/${ENDPOINTS.BUSINESSES}/${businessId}/${ENDPOINTS.DOCUMENTS}`

        return get(url);
    }
}