import {ENDPOINTS, get } from '@/api/mainApi';
import { PaginatedResponse } from '@/interfaces/paginatedResponse';

export class UserAccountRepository {
  constructor() {
  }

  public async searchUsersForOrganization(orgId: number, searchString: string, pageIndex: number): Promise<PaginatedResponse> {
    const paginatedResponse = await get(`${ENDPOINTS.USER_ACCOUNTS}/${ENDPOINTS.ORGANIZATIONS}/${orgId}?q=${searchString}&pageIndex=${pageIndex}`) as PaginatedResponse
    return paginatedResponse
  }
}
