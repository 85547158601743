



















import Vue from 'vue';
import DocumentStatisticsSummaryTable from '@/components/statistics/DocumentStatisticsSummaryTable.vue';
import { isHigherLevelAdmin } from '@/helpers/userAccountHelper';
import { Business } from '@/interfaces/business/business';
import { ENDPOINTS, get } from '@/api/mainApi';
import { UserAccount } from '@/interfaces/useraccount/userAccount';
import { SelectItem } from '@/interfaces/Vuetify';

export default Vue.extend({
    components: {
        DocumentStatisticsSummaryTable,
    },
    props: {
        useOrg: Boolean,
    },
    data() {
        return {
            loadingBusinesses: false,
            businessOptions: [] as Array<SelectItem>,
            selectedBusinessId: null
        }
    },
    mounted() {
        if (this.isHigherLevelAdmin(this.$store.state.userSession.userAccount) && !this.useOrg) {
            this.getSelectionItems()
        } else {
            this.selectedBusinessId = this.getBusinessId()
        }
    },
    computed: {
        getOrganizationName(): string {
            return this.$store.state.userSession.userAccount.business.organization.name ?? "Organisation" 
        },
        getOrganizationId(): number {
            return this.$store.state.userSession.userAccount.business.organization.id
        },
        getBusinessName(): string {
            return this.$store.state.userSession.userAccount.office.business.name ?? "Företag";
        },
        user(): UserAccount {
            return this.$store.state.userSession.userAccount;
        },
    },
    methods: {
        async getSelectionItems() {
            this.loadingBusinesses = true;
            let businesses: Array<Business> = await get(`${ENDPOINTS.ORGANIZATIONS}/${this.getOrganizationId}/${ENDPOINTS.BUSINESSES}`);
            let options: SelectItem[] = []
            for (const business of businesses) {
                options.push({
                    text: business.name,
                    value: business.id,
                })
            }
            this.businessOptions = options;
            this.loadingBusinesses = false;
        },
        getBusinessId(): number {
            return this.$store.state.userSession.userAccount.business.id
        },
        setBusinessId(businessId) {
            this.selectedBusinessId = businessId
        },
        isHigherLevelAdmin,
    }
})
